import { useMemo } from 'react';
import type { AppProps, NextWebVitalsMetric } from 'next/app';
import { ThemeProvider } from 'next-themes';

import { getI18nSettings } from '@admin/i18n.settings';
import { FlashMessageHolder } from '@admin/molecules/FlashMessage';
import { I18nProvider } from '@cms/i18n/provider';
import { FontFaces } from '@common/atoms/FontFaces/pages-router';
import { FallbackStyleBlock } from '@common/atoms/FontFaces/pages-router/atoms/FallbackStyleBlock';
import { SupertokensWrapper } from '@common/atoms/SupertokensWrapper';
import { PlatformID } from '@common/clients/api';
import { ContextData, getThemeClass, isContextData, ResolvedContextData } from '@common/defaults';
import { logger } from '@common/logger';
import { ThemeID } from '@common/types/ThemeID';
import { ContextDataProvider } from '@common/useContextData';
import { UserContextProvider } from '@common/useUserContext/UserContextProvider';

import '../styles/index.scss';

function MyApp({ Component, pageProps: props }: AppProps) {
    const contextData: ContextData | undefined = isContextData(props?.contextData)
        ? props.contextData
        : undefined;
    const theme = getThemeClass(ThemeID.ADMIN);
    const config = useMemo(() => getI18nSettings(contextData?.context.locale), [contextData]);

    return (
        <I18nProvider config={config}>
            <ThemeProvider attribute="class" themes={[theme]} forcedTheme={theme}>
                {contextData?.platform.id === PlatformID.VI ? (
                    <FallbackStyleBlock />
                ) : (
                    <FontFaces platform={contextData?.platform.id || PlatformID.VP} isAdmin={true} />
                )}
                {contextData && props.userContext ? (
                    <SupertokensWrapper
                        autoRefresh={true}
                        contextData={contextData}
                        userContext={props.userContext}
                    >
                        <ContextDataProvider contextData={contextData as ResolvedContextData}>
                            <UserContextProvider userContext={props.userContext}>
                                <Component {...props} />
                            </UserContextProvider>
                            <FlashMessageHolder />
                        </ContextDataProvider>
                    </SupertokensWrapper>
                ) : (
                    <Component {...props} />
                )}
            </ThemeProvider>
        </I18nProvider>
    );
}

export function reportWebVitals(metric: NextWebVitalsMetric) {
    logger.debug(metric);
    if (typeof global.window.gtag !== 'undefined') {
        window.gtag('event', metric.name, {
            event_category: metric.label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
            value: Math.round(metric.name === 'CLS' ? metric.value * 1000 : metric.value),
            event_label: metric.id,
            non_interaction: true,
        });
    }
}

export default MyApp;
