import { getConfig, locales } from '@cms/i18n';

import { AdminRoute } from './routing/AdminRoute';
import { NamespacesByRoute } from './routing/NamespacesByRoute';

export const defaultNamespaces = ['actions', 'common', 'components', 'datetime', 'url', 'rich-editor'];

const defaultLocale = 'en';

export function getI18nSettings(lng = defaultLocale, ns = defaultNamespaces) {
    if (!locales.includes(lng)) {
        throw new Error(`Invalid locale: ${lng}`);
    }

    return getConfig(lng, ns);
}

export const routeNamespaces: Record<AdminRoute, string[]> = NamespacesByRoute;
